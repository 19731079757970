import clsx from "clsx"
import { getColor } from "../../../data/colors"
import { useEffect, useRef, useState } from "react"
import gsap from "gsap/gsap-core"
import { useGSAP } from "@gsap/react";
import ScrollReveal from "scrollreveal";

function ColorLine(props) {
    const ref = useRef();
    const console = useRef();
    useGSAP(()=>{
        const timeline = gsap.timeline();
        timeline.from(ref.current,{
            duration: props.duration,
            delay: props.delay,
            xPercent: props.reverse ? 100 : -100,
        },"-=0.5").to(ref.current,{
            scaleY: 1,
            duration: props.duration
        }).from(console.current,{
            opacity: 0,
            duration: props.duration,
            yPercent: 50
        },"-=0.25")
    },[]);
    return (
        <div
            className="h-1/4 w-full flex flex-col justify-center scale-y-50"
            style={{
                background: props.color
            }}
            ref={ref}>
            <div className={clsx(
                "flex justify-center h-full max-w-[1100px] mx-auto w-full items-center",
                props.reverse && "flex-row-reverse"
            )}
            ref={console}>
                <figure className={clsx(
                    "h-full",
                    "focus-visible:bg-nintendo-black"
                )} tabIndex={0} aria-label={props.label}>
                    <img src={props.image} alt={props.alt} className="h-full" />
                </figure>
                <button className={clsx(
                    "uppercase border-white border-solid border-2 h-fit font-bold rounded-3xl py-2 px-8 mx-3 text-white",
                    props.button === "#069330" && "bg-[#069330]",
                    props.button === "#FFC001" && "bg-[#FFC001]",
                    props.button === "#011DA9" && "bg-[#011DA9]",
                    props.button === "#FE2015" && "bg-[#FE2015]",
                    "focus-visible:border-6 focus-visible:border-nintendo-black transition-all duration-300 active:scale-90 active:duration-75"
                )} style={{background: props.button}}>Acheter</button>
            </div>
        </div>
    )
}

function ColorLines() {
    const duration = 0.25
    const delay = 0.5;
    return (
        <>
            <ColorLine
                color={getColor("green")}
                image="images/consoles/couleur_bleu.png" 
                duration={duration}
                delay={delay}
                button={getColor("blue")}
                label="Nintendo 64 de couleur bleu."/>
            <ColorLine
                color={getColor("blue")}
                image="images/consoles/couleur_verte.png"
                reverse 
                duration={duration}
                delay={delay*2}
                button={getColor("green")}
                label="Nintendo 64 de couleur verte."/>
            <ColorLine
                color={getColor("yellow")}
                image="images/consoles/couleur_rouge.png" 
                duration={duration}
                delay={delay*3}
                button={getColor("red")}
                label="Nintendo 64 de couleur rouge."/>
            <ColorLine
                color={getColor("red")}
                image="images/consoles/couleur_jaune.png"
                reverse 
                duration={duration}
                delay={delay*4}
                button={getColor("yellow")}
                label="Nintendo 64 de couleur jaune."/>
        </>
    )
}


function Squares(props){

    const ref = useRef();
    const firstSquare = useRef();
    const secondSquare = useRef();
    const thirdSquare = useRef();
    const fourthSquare = useRef();


    useEffect(()=>{
        function round(){
            const duration = 0.5;
            gsap.to(firstSquare.current,{
                duration: duration,
                borderRadius: "100%",
                xPercent: -100,
                yPercent: -100
            });
            gsap.to(secondSquare.current,{
                duration: duration,
                borderRadius: "100%",
                xPercent: -100,
                yPercent: 100
            });
            gsap.to(thirdSquare.current,{
                duration: duration,
                borderRadius: "100%",
                xPercent: 100,
                yPercent: -100
            });
            gsap.to(fourthSquare.current,{
                duration: duration,
                borderRadius: "100%",
                xPercent: 100,
                yPercent: 100,
                onComplete: ()=>{
                    rotate();
                }
            })
        }

        function rotate(){
            const timeline = gsap.timeline();
            timeline.to(ref.current,{
                duration: 1,
                rotate: 360,
                onComplete: ()=>{
                    explode();
                }
            })
        }

        function explode(){
            const duration = 0.25;
            gsap.to(firstSquare.current,{
                duration: duration,
                borderRadius: "100%",
                x: "-=100vw",
            });
            gsap.to(secondSquare.current,{
                duration: duration,
                borderRadius: "100%",
                x: "-=100vw"
            });
            gsap.to(thirdSquare.current,{
                duration: duration,
                borderRadius: "100%",
                x: "+=100vw"
            });
            gsap.to(fourthSquare.current,{
                duration: duration,
                borderRadius: "100%",
                x: "+=100vw",
                onComplete: props.onComplete
            })
        }

        ScrollReveal().reveal(ref.current,{
            opacity: 1,
            viewFactor: 1,
            beforeReveal : ()=>{
                round();
            }
        });
    },[props.onComplete]);

    return (
        <div className="w-[150px] aspect-square absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" ref={ref} tabIndex={0}>
            <div className="w-1/2 h-1/2 absolute top-0 left-0" style={{background: getColor("red")}} ref={firstSquare}></div>
            <div className="w-1/2 h-1/2 absolute bottom-0 left-0" style={{background: getColor("yellow")}} ref={secondSquare}></div>
            <div className="w-1/2 h-1/2 absolute right-0 top-0" style={{background: getColor("green")}} ref={thirdSquare}></div>
            <div className="w-1/2 h-1/2 absolute right-0 bottom-0" style={{background: getColor("blue")}} ref={fourthSquare}></div>
        </div>
    )
}

function Colors() {
    const ref = useRef();
    const [linesAnimation, setLinesAnimation] = useState(false);
    const [squares,setSquares] = useState(true);
    return (
        <div className="w-full h-fit" ref={ref}>
            <div className="w-full h-dvh max-h-[1080px] mt-32 bg-nintendo-black relative">
                {squares && <Squares
                onComplete={()=>{
                    setSquares(false);
                    setLinesAnimation(true)
                }}/>}
                {linesAnimation && <ColorLines/>}
            </div>
        </div>
    )
}

export default Colors
function setAccessory(id, title, subtitle, image){
    return {
        id: id,
        title: title,
        subtitle : subtitle,
        image: image,
    }
}

const accessories = [
    setAccessory(1,"Transfer Pak",
    "À l'aide du Transfer Pak, la Nintendo 64 peut communiquer avec des cartouches Game Boy Color comme Pokémon Or/Argent et Mario Golf. Selon le jeu, vous pourrez échanger des données, débloquer des secrets et des mini-jeux, et même jouer à vos jeux Pokémon sur grand écran",
    "images/accessoires/transfer_pak.webp"),
    setAccessory(
        2,
        "Rumble Pak",
        `Un accessoire qui se connecte à la manette et fournit des vibrations en réponse à certaines actions dans le jeu.`,
        "images/accessoires/rumble_pak.png"
    ),
    setAccessory(
        3,
        "Controller Pak",
        "Une carte mémoire qui se branche dans la manette et permet de sauvegarder les données de jeu.",
        "images/accessoires/controller_pak.png"
    ),
    setAccessory(
        4,
        "Nintendo 64DD",
        "Le 64DD se connecte à la Nintendo 64 via le port extension situé sous la console. Il permet à la Nintendo 64 de lire des disques magnétiques. Un modem permettait également de se connecter au réseau Randnet qui offrait différentes options comme le jeu en ligne, un service de messagerie ou la navigation sur Internet.",
        "images/accessoires/64DD.png"
    )
]

function getAccessories(){
    return accessories;
}

export {getAccessories};
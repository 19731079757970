import { useEffect, useRef } from "react";
import ScrollReveal from "scrollreveal";
import Paragraph from "../../text/Paragraph";
import clsx from "clsx";
function FrontController(props){
    const ref = useRef();
    return (
        <figure className="w-full px-10 sticky z-50 bottom-0 left-0 flex justify-center" ref={ref}>
            <img src="images/console/front.png" alt="Nintendo 64" className="max-w-[320px] w-full"/>
        </figure>
    )
}

function Arrow(props){
    return (
        <figure className={clsx(
            "invisible tablet:visible w-1/2 px-5 mb-6"
        )}>
            <img src="images/arrow.svg" alt="Flèche" className="w-full" style={{
                scale : props.right && "-1 1"
            }}/>
        </figure>
    )
}
function Component(props){
    const ref = useRef();
    useEffect(()=>{
        ScrollReveal().reveal(ref.current,{
            viewFactor: 0.5,
            viewOffset : {
                bottom: window.innerHeight/3
            }
        })
    },[])
    return (
        <div className={clsx(
            "mb-[25vh] flex flex-col justify-center w-full max-w-[1100px] mx-auto",
            props.right && "items-end"
        )}>
            <div ref={ref} className={clsx(
                "w-full tablet:w-1/3 flex flex-col",
                !props.right && "items-end"
            )} tabIndex={0} onFocus={(e)=>{
                window.scrollTo(0, e.target.offsetTop - window.screen.availHeight/2)
            }}>
                <Arrow {...props}/>
                <Paragraph text={props.text} className={clsx(
                    "text-center text-[1.5rem]",
                    props.right ? "tablet:text-right" : "tablet:text-left"
                )}/>
            </div>
        </div>
    )
}


function Hardware(props){
    const ref = useRef();
    return (
        <div className="w-full">
            <div className="px-5" ref={ref}>
                <Component text="Processeur principal : NEC VR4300, cadencé à 93,75 MHz."/>
                <Component text="Processeur graphique : Reality Co-Processor (RCP) développé par Silicon Graphics." right/>
                <Component text="Mémoire système : 4 MB Rambus RDRAM."/>
            </div>
            <FrontController/>
        </div>
    )
}

export default Hardware;
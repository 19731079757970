const colors = {
    "black":"#1E1D1D",
    "green":"#0BA239",
    "yellow":"#FFC001",
    "blue":"#011DA9",
    "red":"#FF2F24"
}

function getColor(color){
    return colors[color];
}

export {getColor};
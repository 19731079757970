import { useGSAP } from "@gsap/react";
import gsap from "gsap/gsap-core";
import { useRef } from "react";

gsap.registerPlugin(useGSAP);

function StartAnimation(props){
    const ref = useRef();
    const icon = useRef();
    const screenHeight = window.innerHeight
    const startPosition = window.screenY + screenHeight/2

    useGSAP(()=>{
        let timeline = gsap.timeline();
        timeline.from(icon.current,{
            duration:1,
            xPercent:100,
            opacity:0
        })
        timeline.from(ref.current,{
            duration:1,
            yPercent:-50,
            y: startPosition,
            onComplete: ()=>{
                props.onComplete();
            }
        })
    
    })

    return (
        <div className="absolute top-0 left-0 flex w-full justify-center items-center py-5" ref={ref}>
            <div className="w-full max-w-[500px] flex justify-center items-center" tabIndex={0} aria-label="Logo de la Nintendo 64">
                <img src="images/logo/nintendo_logo.png" alt="Logo" className="w-1/5 mr-2" ref={icon}/>
                <img src="images/logo/nintendo_titre.png" alt="Nintendo 64" className="w-[60%] object-contain"/>
            </div>
        </div>
    )
}

export default StartAnimation;
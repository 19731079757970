import './App.css';
import { useRef, useState } from 'react';
import gsap from 'gsap/gsap-core';
import StartAnimation from './components/StartAnimation';
import Numbers from './components/sections/Numbers';
import { useGSAP } from '@gsap/react';
import TvSection from './components/sections/TvSection';
import Controller from './components/sections/Controller';
import Cardridges from './components/sections/Cardridges';
import Accessories from './components/sections/Accessories';
import Colors from './components/sections/Colors';


gsap.registerPlugin(useGSAP);

function AppContent(){
  const ref = useRef();
  useGSAP(()=>{
    gsap.from(ref.current,{
      duration:0.5,
      opacity:0
    })
  },[]);

  return (
    <div className="w-full pt-32 computer:pt-48" ref={ref}>
      <div className='w-full h-auto max-w-[1100px] mx-auto overflow-hidden'>
        <div className='w-full px-5'>
          <Numbers/>
          <TvSection/>
        </div>
      </div>
      <Controller/>
      <div className='w-full h-auto max-w-[1100px] mx-auto'>
        <Accessories/>
      </div>
      <Cardridges/>
      <Colors/>
    </div>
  )
}

function App() {
  const [active,setActive] = useState(false);

  return (
    <div className="bg-nintendo-black min-h-screen min-w-screen text-white font-roboto font-medium relative">
      <StartAnimation
      onComplete={()=>{
        setActive(true);
      }}/>
      {active && <AppContent/>}
    </div>
  );
}

export default App;

import { useEffect } from "react";
import Caroussel from "../../Caroussel";
import { getAccessories } from "../../../data/accessories";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import Title from "../../text/Title";
import { useRef } from "react";
import ScrollReveal from "scrollreveal";

gsap.registerPlugin(useGSAP);

function Heading(props){
    const ref = useRef();
    useGSAP(()=>{
        gsap.from(ref.current,{
            duration:1,
            y: 30,
            opacity: 0
        })
    },[]);
    return (
        <div className="flex flex-col w-full items-center mb-16" ref={ref}>
            <Title
            text="Accessoires"/>
        </div>
    )
}

function Accessories(){
    const ref = useRef();
    const accessories = getAccessories();
    useEffect(()=>{
        ScrollReveal().reveal(ref.current,{
            viewFactor : 0.5,
            beforeReveal : ()=>{
                gsap.from(ref.current,{
                    duration: 0.5,
                    y: 100
                })
            }
        });
    },[])
    return (
        <div ref={ref}>
            <Heading/>
            <div className="border-nintendo-yellow border-4 border-solid py-10 border-y-0">
                <Caroussel
                content={accessories}/>
            </div>
        </div>
    )
}

export default Accessories;
const cardridges = [
    setCardridge("Super Mario 64","images/cartouches/cartouche_sm64.png"),
    setCardridge("Docteur Mario 64","images/cartouches/cartouche_docteurMario.png"),
    setCardridge("Goldeneye 64","images/cartouches/cartouche_goldeneye.png"),
    setCardridge("Mario Kart 64","images/cartouches/cartouche_marioKart.png"),
    setCardridge("Mario Party","images/cartouches/cartouche_marioParty.png"),
    setCardridge("Zelda Majora's Mask","images/cartouches/cartouche_zeldaMM.png",true)
]

function setCardridge(name, url, last = false){
    return {
        "name" : name,
        "url" : url,
        "last" : last
    }
}

function getCardridges(){
    return cardridges
}
   

export default getCardridges;
import { useGSAP } from "@gsap/react";
import gsap from "gsap/gsap-core";
import { useEffect, useRef, useState } from "react";
import ScrollReveal from "scrollreveal";
import Title from "../../text/Title";
import Paragraph from "../../text/Paragraph";
import { useMediaQuery } from "react-responsive";

gsap.registerPlugin(useGSAP);

function setupNumbers(){
    const numbers = [];
    for(let i=0;i<=9;i++){
        numbers.push(i);
    }
    return numbers;
}

function ScrollingNumber(props){
    const isComputer = useMediaQuery({
        query: "(min-width: 1100px)"
    })
    const ref = useRef();
    const numbers = setupNumbers();

    useEffect(()=>{

        function initiateNumber(){
            const numberHeight = ref.current.clientHeight/numbers.length
            gsap.to(ref.current,{
                duration: 0,
                y: -numberHeight*props.start
            })
        }

        function scrollNumber(){
            const numberHeight = ref.current.clientHeight/numbers.length
            gsap.to(ref.current,{
                duration: props.duration,
                y: -numberHeight*props.end,
                onComplete : ()=>{
                    if(props.onComplete){
                        props.onComplete();
                    }
                }
            })
        }

        initiateNumber();
        ScrollReveal().reveal(ref.current,{
            duration: 3,
            beforeReveal : ()=>{
                scrollNumber();
            },
        })
    },[numbers,props])
    const blockHeight = isComputer ? "8rem" : "6rem"
    return (
        <div className="overflow-hidden"
        style={{
            height: blockHeight
        }}>
            <div className="flex flex-col justify-center items-center" ref={ref}>
                {numbers.map(
                    v=><span className="font-myriad"
                    style={{
                        fontSize: blockHeight,
                        lineHeight: blockHeight
                    }}>{v}</span>
                )}
            </div>
        </div>
    )
}

function Heading(props){
    const ref = useRef();
    useEffect(()=>{
        if(props.display){
            gsap.from(ref.current,{
                duration:1,
                y: 30,
                opacity: 0
            })
        }
    })

    return (
        <div className="mt-10 flex flex-col w-full items-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" ref={ref} style={{opacity: props.display ? "1" : "0"}}>
            <Title
            text="La machine-plaisir"/>
            <Paragraph
            text={`Le chiffre "64" pour les 64 bits de riches graphismes que la Nintendo 64 apporte dans votre télévision.`}
            className="text-center mt-2 font-bold"/>
        </div>
    )
}

function Numbers(){
    const firstNumber = useRef();
    const secondNumber = useRef();
    const scrollingDuration = 1;
    const [heading, setHeading] = useState(false);

    function splitNumbers(){
        const duration = 0.5
        gsap.to(firstNumber.current,{
            duration:duration,
            x:-window.innerWidth,
            opacity: 0,
            onComplete : ()=>{
            }
        })
        gsap.to(secondNumber.current,{
            duration:duration,
            x:window.innerWidth,
            opacity: 0,
            onComplete : ()=>{
                setHeading(true);
            }
        })
    }

    return(
        <div className="relative">
            <div className="flex justify-center">
                <div ref={firstNumber}>
                    <ScrollingNumber
                    start={1}
                    end={6}
                    duration={scrollingDuration}/>
                </div>
                <div ref={secondNumber}>
                    <ScrollingNumber
                    start={6}
                    end={4}
                    duration={scrollingDuration}
                    onComplete = {splitNumbers}/>
                </div>
            </div>
            <Heading
            display={heading}/>
        </div>
    )
}

export default Numbers;
import clsx from "clsx";

function Paragraph(props){
    return (
        <p className={clsx(
            props.className,
            "text-[0.75rem] font-bold tablet:text-1 tablet:text-[1rem] computer:text-[1.25rem]"
        )} tabIndex={0}>
            {props.text}
        </p>
    )
}

export default Paragraph;
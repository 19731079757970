
import { useEffect, useRef, useState } from "react";
import gsap from "gsap/gsap-core";
import { useGSAP } from "@gsap/react";
import ScrollReveal from "scrollreveal";
import Title from "../../text/Title";
import Paragraph from "../../text/Paragraph";
import { useMediaQuery } from "react-responsive";

gsap.registerPlugin(useGSAP);

function getRandomInt(min = 0, max) {
    const minCeiled = Math.ceil(min);
    const maxFloored = Math.floor(max);
    return Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled); // The maximum is exclusive and the minimum is inclusive
}


function generateButton(xMax, yMax, buttonWidth) {
    let randX = getRandomInt(0, xMax - buttonWidth);
    let randY = getRandomInt(0, yMax - buttonWidth);
    return {
        "topLeft": {
            "x": randX,
            "y": randY
        },
        "left": randX,
        "right": randX + buttonWidth,
        "top": randY,
        "bottom": randY + buttonWidth
    }
}

function generateButtons(xMax, yMax, buttonWidth) {
    var buttons = [];

    function isValidButton(checkedButton) {
        for (let button in buttons) {
            if (
                ((checkedButton.left > button.left && checkedButton.left < button.right) || (checkedButton.right < button.right && checkedButton.right > button.left))
                ||
                ((checkedButton.top > button.top && checkedButton.top < button.bottom) || (checkedButton.bottom < button.bottom && checkedButton.nottom > button.top))
            ) {
                return false;
            }
        }
        return true;
    }

    for (let i = 0; i < getRandomInt(10, 15); i++) {
        var button = generateButton(xMax, yMax, buttonWidth);
        while (!isValidButton(button)) {
            button = generateButton(xMax, yMax, buttonWidth)
        }
        buttons.push(button);
    }

    return buttons;
}

function Button(props) {
    const ref = useRef();
    useGSAP(() => {
        gsap.from(ref.current, {
            duration: 0.25,
            scale: 0
        })
    }, []);
    useEffect(() => {
        ScrollReveal().reveal(ref.current, {
            opacity: 1,
            viewOffset: {
                bottom: window.innerHeight / 4
            },
            beforeReveal: () => {
                gsap.to(ref.current, {
                    duration: 0.5,
                    opacity: 0,
                    y: -500
                })
            }
        });
    }, []);
    return (
        <img src={props.image} alt={props.alt} width={props.size} className="absolute button" style={{
            left: props.x,
            top: props.y
        }} ref={ref} />
    )
}

function Buttons(props) {
    const ref = useRef();
    const [buttons, setButtons] = useState([]);
    const buttonWidth = 64;


    function getButtons() {
        let res = [];
        let images = [{
            src: "images/boutons/Bouton_A.png",
            alt: "Bouton A"
        },
        {
            src: "images/boutons/Bouton_B.png",
            alt: "Bouton B"
        },
        {
            src: "images/boutons/Bouton_fleche_bas.png",
            alt: "Bouton C-bas"
        },
        {
            src: "images/boutons/Bouton_fleche_haut.png",
            alt: "Bouton C-haut"
        },
        {
            src: "images/boutons/Bouton_fleche_gauche.png",
            alt: "Bouton C-gauche"
        },
        {
            src: "images/boutons/Bouton_fleche_droit.png",
            alt: "Bouton C-droit"
        }];

        let buttonObjects = generateButtons(ref.current.clientWidth, ref.current.clientHeight, buttonWidth);

        for (let buttonObject of buttonObjects) {
            var image = images[Math.floor(Math.random() * images.length)];
            res.push(
                {
                    "x": buttonObject.topLeft.x,
                    "y": buttonObject.topLeft.y,
                    "image": image.src,
                    "alt": image.alt,
                    "size": buttonWidth
                }
            )
        }

        return res;
    }

    useEffect(() => {
        setButtons(getButtons());
    }, []);

    return (
        <div className="w-full h-[400px] tablet:h-[800px] relative max-w-[1000px]" ref={ref}>
            {buttons.map(
                v => <Button
                    {...v}
                />
            )}
        </div>
    )
}

function Heading(props) {
    const ref = useRef();
    useEffect(() => {
        if (props.hidden) {
            gsap.to(ref.current, {
                delay: 0.5,
                duration: 1,
                y: 30,
                opacity: 0
            })
        } else {
            gsap.to(ref.current, {
                delay: 0.5,
                duration: 1,
                y: 0,
                opacity: 1
            })
        }
    })
    return (
        <div className="flex flex-col w-full items-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 opacity-0" ref={ref}>
            <Title
                text="Quadruplez le plaisir" />
            <Paragraph
                text="La Nintendo 64 a été conçue comme une machine festive, avec quatre ports manette qui ne demandent qu'à être utilisés."
                className="text-center mt-2 font-bold" />
        </div>
    )
}

function ControllerImage() {
    const isZoomed = useMediaQuery({
        query: "(max-height: 280px)"
    });
    return (
        <figure className="w-[200px] tablet:w-[300px]" style={{ display: isZoomed && "none" }}>
            <img src="images/manette.png" alt="Manette de la Nintendo 64" className="w-full" />
        </figure>
    )
}

function ControllerImages(props) {
    const ref = useRef();
    const firstController = useRef();
    const secondController = useRef();
    const thirdController = useRef();
    const fourthController = useRef();


    function translateController(controller, xPercent, yPercent, instant = false, completeFunction = true) {
        gsap.to(controller, {
            duration: instant ? 0 : 1,
            left: xPercent + "%",
            top: yPercent + "%"
        });
    }

    const {onComplete} = props;

    useEffect(() => {
        function translateControllers(instant = false, completeFunction = true) {
            translateController(firstController.current, 0, 10, instant, false)
            translateController(secondController.current, 100, 10, instant, false)
            translateController(thirdController.current, 100, 90, instant, false)
            translateController(fourthController.current, 0, 90, instant, completeFunction)
            onComplete();
        }
        ScrollReveal().reveal(ref.current, {
            opacity: 1,
            viewFactor: 0.33,
            beforeReveal: () => {
                translateControllers();
            }
        });
    }, [onComplete]);

    const className = "w-fit absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex justify-center items-center"
    return (
        <div className="w-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 h-full max-h-screen" ref={ref}>
            <div className={className} ref={firstController}>
                <ControllerImage />
            </div>
            <div className={className} ref={secondController}>
                <ControllerImage />
            </div>
            <div className={className} ref={thirdController}>
                <ControllerImage />
            </div>
            <div className={className} ref={fourthController}>
                <ControllerImage />
            </div>
        </div>
    )
}

function Controller() {
    const ref = useRef();
    const [heading, setHeading] = useState(false);
    return (
        <div className="w-screen relative py-32 computer:my-48 max-w-screen overflow-hidden">
            <div className="relative w-full max-w-[1100px] mx-auto" ref={ref}>
                <ControllerImages
                    onComplete={() => {
                        setHeading(true);
                    }} />
                <Buttons />
                <Heading
                    hidden={!heading} />
            </div>
        </div>
    )
}

export default Controller;
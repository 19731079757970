import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import 'swiper/css';
import clsx from 'clsx';
import { useState } from 'react';
import { A11y } from 'swiper/modules';
import { useMediaQuery } from 'react-responsive';

function CarousselButton(props){
    const swiper = useSwiper();
    return (
        <button className="min-w-[32px] max-w-[32px] tablet:min-w-[64px] mx-3 transition-all hover:scale-110 active:grayscale active:duration-[50ms]" onClick={()=>{
            if(props.previous){
                swiper.slidePrev();
            } else {
                swiper.slideNext();
            }
        }}>
            <img
            src={props.previous ? "images/boutons/Bouton_fleche_gauche.png" : "images/boutons/Bouton_fleche_droit.png"}
            alt={props.previous ? "Précédent" : "Suivant"}
            className='w-full'/>
        </button>
    )
}

function CarousselItem(props){
    return (
        <div className={
            clsx(
                props.isActive ? "opacity-100" : "opacity-25",
                "w-full transition-all")} tabIndex={props.isActive && 0} aria-label={props.title}>
            <img src={props.image} className='w-full h-full object-contain max-h-[200px]' alt={props.title}/>
        </div>
    )
}

function CarousselHeading(props){
    return (
        <div className='flex flex-col px-10 items-center mt-8'>
            <h3 className='font-myriad tablet:text-[1.5rem] computer:text-[1.75rem]' tabIndex={0}>{props.title}</h3>
            <p className='text-center text-[0.625rem] tablet:text-[0.75rem] computer:text-[1rem]' tabIndex={0}>{props.subtitle}</p>
        </div>
    )
}

function Caroussel(props){
    const isTablet = useMediaQuery({
        query : "(min-width: 800px)"
    });
    const isComputer = useMediaQuery({
        query : '(min-width: 1100px)'
    })
    function getSlidesPerView(){
        if(isComputer){
            return 2.5;
        } else if(isTablet){
            return 1.75;
        } else {
            return 1.25;
        }
    }
    const [activeIndex, setActiveIndex] = useState(0);
    return (
        <Swiper
        modules={[A11y]}
        a11y={{
           prevSlideMessage : "Element précédent",
           nextSlideMessage : "Element suivant" 
        }}
        centeredSlides={true}
        pagination={true}
        loop={true}
        slidesPerView={getSlidesPerView()}
        onSlideChange={(swiper)=>{
            setActiveIndex(swiper.realIndex);
        }}
        >
            {props.content.map(
                v=><>
                <SwiperSlide
                key={v.id}>
                    {({isActive})=>(
                        <CarousselItem
                        isActive={isActive} {...v}/>
                    )}
                </SwiperSlide>
                </>
            )}
            <div className='flex items-center justify-center w-full px-5 mt-8 tablet:mt-16'>
                <CarousselButton
                previous/>
                <CarousselButton/>
            </div>
            <CarousselHeading {...props.content[activeIndex]}/>
            
        </Swiper>
    )
}

export default Caroussel;
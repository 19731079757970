import { useRef, useState } from "react";
import Title from "../../text/Title";
import Paragraph from "../../text/Paragraph";
import gsap from "gsap/gsap-core";
import { useGSAP } from "@gsap/react";

// Call registerPlugin after importing gsap
gsap.registerPlugin(useGSAP);

function TvSection() {
    const ref = useRef();
    const firstSection = useRef();
    const secondSection = useRef();
    const [loaded, setLoaded] = useState(false);

    function reveal() {
        setLoaded(true);
        gsap.from(firstSection.current, {
            opacity: 0,
            duration: 0.5,
            xPercent: -100
        });
        gsap.from(secondSection.current, {
            opacity: 0,
            duration: 0.5,
            xPercent: 100
        });
    }

    useGSAP(() => {
        reveal();
    }, []);

    return (
        <div className="mt-32 computer:mt-64 computer:flex justify-between items-center flex-row-reverse" ref={ref} style={{ opacity: loaded ? 1 : 0 }}>
            <div ref={secondSection} tabIndex={0} aria-label="Affichage du jeu Mario Kart 64 sur une TV Cathodique.">
                <figure className="flex justify-center">
                    <img src="images/tv.png" className="w-full aspect-screen object-contain max-w-[300px] tablet:max-w-[400px]" alt="Affichage du jeu Mario Kart 64 sur une TV Cathodique." />
                </figure>
            </div>
            <div className="flex flex-col items-center mt-5 computer:w-1/2" ref={firstSection}>
                <Title
                    text="Résolution graphique" />
                <Paragraph
                    text="Exploitant chaque astuce graphique existante, la Nintendo 64 vous invite à explorer des mondes époustouflants en 3D qui regorgent de couleurs, s'animent à l'aide d'effets graphiques en temps réel et séduisent vos oreilles avec un son de qualité CD."
                    className="mt-2 text-center computer:text-justify" />
            </div>
        </div>
    );
}

export default TvSection;

import { useCallback, useEffect, useRef, useState } from "react";
import getCardridges from "../../../data/cardridges";
import ScrollReveal from "scrollreveal";
import gsap from "gsap/gsap-core";
import Hardware from "../Hardware";
import { useMediaQuery } from "react-responsive";
import clsx from "clsx";
import { useGSAP } from "@gsap/react";
import Title from "../../text/Title";
import Paragraph from "../../text/Paragraph";

gsap.registerPlugin(useGSAP);

function Cardridge(props) {
    const ref = useRef();
    const notFullSize = useMediaQuery({
        query: "(min-width: 400px)"
    });
    useEffect(()=>{
        if(props.last){
            if(props.scaleDown){
                gsap.to(ref.current,{
                    duration: 0.5,
                    width: "60%",
                    height :"fit-content",
                    translateY: "-25%"
                })
            } else {
                gsap.to(ref.current,{
                    duration: 0.5,
                    width: "100%",
                    height : null,
                    translateY: 0
                })
            }
        } else {
            if(props.scaleDown){
                gsap.to(ref.current,{
                    duration: 0.25,
                    opacity : 0
                })
            } else {
                gsap.to(ref.current, {
                    duration : 0.25,
                    opacity: 1
                })
            }
        }
    });
    
    return (
        <div className={
            clsx("h-full max-h-full px-10 flex flex-col justify-center max-w-[400px]",
            notFullSize ? "min-w-[400px]" : "min-w-full")
        } tabIndex={0} onFocus={props.onFocus} aria-label={props.name   }>
            <figure className="w-full flex h-full flex-col justify-end items-center">
                <img src={props.url} alt={props.name} className="w-full relative h-full object-contain" ref={ref}/>
                {!props.scaleDown && <figcaption className="font-myriad uppercase">{props.name}</figcaption>}
            </figure>
        </div>
    )
}

function Heading(props){
    const ref = useRef();
    useGSAP(()=>{
        gsap.from(ref.current,{
            duration:1,
            y: 30,
            opacity: 0
        })
    },[]);
   
    useEffect(()=>{
        if(props.hidden){
            gsap.to(ref.current,{
                duration:0.25,
                opacity: 0
            })
        } else {
            gsap.to(ref.current,{
                duration:0.25,
                opacity: 1
            })
        }
    });
    return (
        <div className={clsx(
            "flex flex-col w-full items-center justify-center px-5",
            props.isZoomed ? "h-1/2" : "h-1/3 "
        )} ref={ref}>
             <Title
            text="Jeux emblématiques"
            onFocus={props.onFocus}/>
            <Paragraph
            text="Certains de ces jeux sont considérés comme des classiques intemporels et ont grandement contribué à la réputation de la console."
            className="text-center mt-2 font-bold"/>
        </div>
    )
}

function Cardridges() {
   
    const ref = useRef();
    const cardridges = getCardridges();
    const [spacer,setSpacer] = useState(0);
    const cardridgesWrapper = useRef();
    const stickyWrapper = useRef();
    const [scaleDown, setScaleDown] = useState(false);
    const isZoomed = useMediaQuery({
        query : "(max-height: 280px)"
    });

    const getMaxTranslate = useCallback(()=>{
        const cardridgeWidth = cardridgesWrapper.current.children[0].offsetWidth
        return (cardridgeWidth*(cardridges.length)) - window.innerWidth/2 - cardridgeWidth/2
    },[cardridges]);

    useEffect(()=>{
        function handleScroll() {
            const position = window.scrollY;
            const translateValue = position-ref.current.offsetTop
            const maxTranslate = getMaxTranslate();
            if(translateValue > 0){
                if(translateValue<maxTranslate){
                    gsap.to(cardridgesWrapper.current,{
                        duration:0.25,
                        x:-translateValue
                    })
                    setScaleDown(false);
                } else {
                    gsap.to(cardridgesWrapper.current,{
                        duration: 0.25,
                        x: -maxTranslate
                    })
                    setScaleDown(true);
                }
            } else {
                gsap.to(cardridgesWrapper.current,{
                    duration:0.25,
                    x:0
                })
                setScaleDown(false);
            }
        };

        function updateSpacer(){
            setSpacer(getMaxTranslate());
        }

        updateSpacer();
        ScrollReveal().reveal(ref.current,{
            opacity: 0,
            viewOffset: {
                bottom: window.innerHeight/2
            },
            beforeReveal : ()=>{
                window.addEventListener("scroll",handleScroll);
                window.addEventListener("resize",()=>{
                    updateSpacer()
                    handleScroll()
                });
                gsap.from(cardridgesWrapper.current.children,{
                    duration: 0.25,
                    stagger: 0.25,
                    y: 100,
                    opacity: 0
                })
            }
        });
    },[getMaxTranslate]);

    useEffect(()=>{
       if(scaleDown){
            gsap.to(cardridgesWrapper.current,{
                duration: 0.5,
                yPercent: !isZoomed && 100
            })
       } else {
            gsap.to(cardridgesWrapper.current,{
                duration: 0.5,
                yPercent: 0
            })
       }
    },[scaleDown, isZoomed])

  
    return (
        <>
        <div className="w-full h-full min-h-screen mt-32" ref={ref}>
            <div className="sticky top-0 left-0 w-screen h-dvh overflow-hidden" ref={stickyWrapper}>
                <Heading
                hidden={scaleDown}
                isZoomed={isZoomed}
                onFocus={()=>{
                    window.scrollTo(0,ref.current.offsetTop)
                }}/>
                <div className={clsx(
                    "flex items-center w-full",
                    isZoomed ? "h-1/2" : "h-1/3"
                )} ref={cardridgesWrapper}>
                        {cardridges.map(
                            v => <Cardridge {...v} scaleDown={scaleDown} onFocus={()=>{
                                if(v.last){
                                    window.scrollTo(0,ref.current.offsetTop + getMaxTranslate() - 1)
                                } else {
                                    window.scrollTo(0,ref.current.offsetTop)
                                }
                            }}/>
                        )}
                </div>
            </div>
            <div style={{height:spacer}}></div>
            <Hardware/>
        </div>
        </>
    )
}

export default Cardridges;